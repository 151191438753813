<template>
  <a-form
    :colon="false"
    layout="vertical"
    :model="values"
    ref="form"
    @finish="onFinish"
  >
    <div class="card">
      <div class="card-header">
        Login
      </div>
      <div class="card-body">
        <p>Enter your email address and password.</p>
        <a-form-item
          name="email"
          :rules="[
            { required: true, message: 'Email address is required.' },
            { type: 'email', message: 'Email address is invalid.' },
          ]"
        >
          <a-input
            v-model:value="values.email"
            name="email"
            placeholder="name@company.com"
            type="email"
          />
        </a-form-item>
        <a-form-item
          name="password"
          :rules="[
            { required: true, message: 'Password is required.' },
            { min: 6, message: 'Password must be at least 6 characters.' },
          ]"
        >
          <a-input
            v-model:value="values.password"
            name="password"
            type="password"
            placeholder="Password"
          />
        </a-form-item>
      </div>
      <div class="card-footer">
        <a-button type="primary" html-type="submit" :loading="loading">
          Login
        </a-button>
        <a-button type="link" @click="$router.push({ name: 'reset' })">
          Forgot password?
        </a-button>
      </div>
    </div>
  </a-form>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      values: {
        email: null,
        password: null,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        if (this.$route.params.id) {
          var { data } = await this.$http.post(`/session`, {
            _csrf: this.$store.state.csrfToken,
            ...values,
            inviteEmailToken: this.$route.params.id,
          })
        } else {
          var { data } = await this.$http.post(`/session`, {
            _csrf: this.$store.state.csrfToken,
            ...values,
          })
        }

        this.$store.commit('user', data)
        this.$router.push({ name: 'index' })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
