<template>
  <div v-if="loading" class="text-center m-5" style="min-height: 2.5rem;">
    <div v-if="delayedLoading" class="spinner-border" role="status"></div>
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },

  data() {
    return {
      delayedLoading: false,
    }
  },

  created() {
    setTimeout(() => {
      this.delayedLoading = true
    }, 250)
  },
}
</script>
