<template>
  <div class="container">
    <TheHeader />
    <div class="row justify-content-center">
      <div class="col-12 col-md-8 col-lg-6 col-xl-4">
        <BaseSpinner :loading="loading">
          <div v-if="publication">
            <p class="lead">You’re been invited to join <strong>{{ publication.name }}</strong>. 🥳 Log in with your existing account or create a new one.</p>
            <a-tabs v-model:value="activeTab" defaultActiveKey="login">
              <a-tab-pane key="login">
                <span slot="tab">
                  <ArrowRightOnRectangleIcon class="icon me-2" />Use Existing Account
                </span>
                <LoginForm />
              </a-tab-pane>
              <a-tab-pane key="register">
                <span slot="tab">
                  <UserPlusIcon class="icon me-2" />Create New Account
                </span>
                <RegisterForm :email="publication.user.invite.email" />
              </a-tab-pane>
            </a-tabs>
          </div>
          <div v-else class="alert alert-danger">
            Your invite token is invalid or has already been used.
          </div>
        </BaseSpinner>
      </div>
    </div>
  </div>
</template>

<script>
import { ArrowRightOnRectangleIcon, UserPlusIcon } from '@heroicons/vue/24/outline'
import TheHeader from '@/components/TheHeader'
import BaseSpinner from '@/components/Base/BaseSpinner'
import LoginForm from '@/components/Login/LoginForm'
import RegisterForm from '@/components/Register/RegisterForm'

export default {
  components: {
    ArrowRightOnRectangleIcon,
    TheHeader,
    BaseSpinner,
    LoginForm,
    RegisterForm,
    UserPlusIcon,
  },

  data() {
    return {
      loading: true,
      activeTab: 'login',
      publication: null,
    }
  },

  async mounted() {
    try {
      this.loading = true

      var { data } = await this.$http.get(`/invites/${ this.$route.params.id }`)

      this.publication = data
    } catch (err) {
      if (err.response) {
        this.$message.error(err.response.data.message)
      } else {
        this.$message.error(err.message)
      }
    } finally {
      this.loading = false
    }
  },
}
</script>
