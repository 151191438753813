<template>
  <a-form
    :colon="false"
    layout="vertical"
    :model="values"
    ref="form"
    @finish="onFinish"
  >
    <div class="card">
      <div class="card-header">
        New Account
      </div>
      <div class="card-body">
        <p>Enter your author information to create a new account with your email address <strong>{{ email }}</strong>.</p>
        <a-form-item
          name="name"
          :rules="[
            { required: true, message: 'Name is required.' },
          ]"
        >
          <a-input
            v-model:value="values.name"
            name="name"
            placeholder="Enter your name"
          />
        </a-form-item>
        <a-form-item
          name="password"
          :rules="[
            { required: true, message: 'Password is required.' },
            { min: 6, message: 'Password length must be at least 6.' },
          ]"
        >
          <a-input
            v-model:value="values.password"
            name="password"
            type="password"
            placeholder="Choose a secure password"
          />
        </a-form-item>
        <a-form-item
          name="passwordConfirm"
          :rules="[
            { required: true, message: 'Confirmation password is required.' },
            { min: 6, message: 'Password length must be at least 6.' },
          ]"
        >
          <a-input
            v-model:value="values.passwordConfirm"
            name="passwordConfirm"
            type="password"
            placeholder="Confirm your secure password"
          />
        </a-form-item>
      </div>
      <div class="card-footer">
        <a-button type="primary" html-type="submit" :loading="loading">
          Register
        </a-button>
      </div>
    </div>
  </a-form>
</template>

<script>
export default {
  props: {
    email: String,
  },

  data () {
    return {
      loading: false,
      values: {
        name: null,
        password: null,
        passwordConfirm: null,
      },
    }
  },

  methods: {
    async onFinish() {
      try {
        var values = await this.$refs.form.validateFields()

        this.loading = true

        if (this.$route.params.id) {
          await this.$http.put(`/invites/${ this.$route.params.id }`, values)
        } else {
          await this.$http.post('/me', values)
        }

        this.$message.success('Your account has been created.')
        this.$router.push({ name: 'login' })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          console.error(err)
        }
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
